import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ShipmentAddressFormConfiguration
} from "../../../../components/shipment-address-form/shipment-address-form.configuration";
import {
  ShipmentAddressFormComponent
} from "../../../../components/shipment-address-form/shipment-address-form.component";
import { IndirizzoSpedizioneModel } from "../../../../core/classes/spedizione/indirizzo";
import { SnackBarService } from "../../../../core/lib/snakbar.service";

@Component({
  selector: "app-shipment-create-step2",
  templateUrl: "./shipment-create-step2.component.html",
  styles: [`
    ::ng-deep .shipment-create-cntr {
      .shipment-address-form-actions {
        justify-content: start !important;
      }
    }`
  ]
})
export class ShipmentCreateStep2Component {

  @Output() onSubmit: EventEmitter<null> = new EventEmitter<null>();
  @ViewChild("shipmentAddressFormComponent") shipmentAddressFormComponent: ShipmentAddressFormComponent | null = null;

  form: FormGroup;
  shipmentAddressFormConfiguration: ShipmentAddressFormConfiguration = ShipmentAddressFormConfiguration
    .create()
    .enableCustomerAddressSuggestions(true);

  constructor(private formBuilder: FormBuilder, private snackBarService: SnackBarService) {

    this.form = this.formBuilder.group({});

  }

  ngOnInit() {

    this.shipmentAddressFormConfiguration
      .submitButton
      .setLabel("Salva spedizione");

  }

  ngAfterViewInit() {

    this.form
      .addControl("shipmentAddressData", this.shipmentAddressFormComponent?.form);


  }

  updatedAddress(result: IndirizzoSpedizioneModel | null) {

    this.snackBarService
      .generalMessage("Indirizzo salvato!");

    this.shipmentAddressFormComponent
      ?.updateCustomerSuggestions();

  }

}
