import { Injectable } from "@angular/core";
import { BaseController } from "../../core/controllers/base.controller";
import { ModelDetailService } from "../../components/model-detail/model-detail.service";
import { ModelDetailConfiguration } from "../../components/model-detail/model-detail.configuration";
import { QueriesService } from "../../core/lib/queries.service";
import { SpedizioneApolloResult } from "../../core/interfaces/apollo/spedizione.apollo-result";
import { iconsBasePath } from "../../core/constants";
import { SpedizioneModel } from "../../core/classes/spedizione/spedizione.model";
import {
  SectionDetailDataConfiguration
} from "../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { DetailDataConfiguration } from "../../components/model-detail/section-detail-data/detail-data.configuration";
import { ContenutoConfezioneSpedizione } from "../../core/classes/spedizione/contenuto-confezione-spedizione";
import { GenericReadModel } from "../../core/classes/generic-read.model";
import { StatoSpedizioneEnum } from "../../core/enum/stato-spedizione.enum";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ShipmentService {

  statiSpedizione = Object.keys(StatoSpedizioneEnum).map(key => (<any>StatoSpedizioneEnum)[key]);

  constructor(
    private baseController: BaseController,
    private detailDataService: ModelDetailService,
    private modelDetailService: ModelDetailService,
    private translate: TranslateService,
    private _queries: QueriesService) {
  }

  async getShipmentData(shipmentId: string | null): Promise<SpedizioneModel | null> {

    if (!shipmentId) return null;

    const query = await this._queries.entities.spedizione();

    if (!query) return null;

    const caller = await this.baseController.get<SpedizioneApolloResult>(shipmentId, query);

    if (!caller) return null;

    try {

      const result = await new Promise<SpedizioneApolloResult>((resolve, reject) => {

        caller
          .subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.spedizione ?? null;

    } catch (error) {

      // Gestire eventuali errori qui
      console.error(error);
      return null;

    }

  }

  private getDestinatarioSpedizioneModelDetailSection(value: SpedizioneModel) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
      .create()
      .enableEndSectionDivider(true);

    const destinatarioNome = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(
        "CT_GENERAL.name",
        `${value.destinatario.nome}`,
        iconsBasePath + "customer.svg")
      .setFxFlex(33.33)
      .setFxLayout(null);

    const destinatarioEmail = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(
        "CT_GENERAL.email",
        `${value.destinatario.email ?? ""}`,
        iconsBasePath + "email.svg")
      .setFxFlex(33.33)
      .setFxLayout(null);

    const destinatarioTelefono = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(
        "CT_GENERAL.phone_number",
        `${value.destinatario.telefono}`,
        iconsBasePath + "phone.svg")
      .setFxFlex(33.33)
      .setFxLayout(null);

    section
      .addDetailData(destinatarioNome)
      .addDetailData(destinatarioEmail)
      .addDetailData(destinatarioTelefono);

    return section;

  }

  private getIndirizzoSpedizioneModelDetailSection(value: SpedizioneModel) {

    const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
      .create()
      .enableEndSectionDivider(true);

    const detailIndirizzoComune = DetailDataConfiguration
      .create()
      .setCTCardConfigurationFromDetailDataModel(
        "CT_GENERAL.address",
        `${value.destinatario.indirizzo.via}, ${value.destinatario.indirizzo.comune}, ${value.destinatario.indirizzo.cap}`,
        iconsBasePath + "location.svg")
      .setFxFlex(100)
      .setFxLayout(null);

    section
      .addDetailData(detailIndirizzoComune);

    return section;

  }

  private getColliSpedizioneModelDetailSections(value: SpedizioneModel): SectionDetailDataConfiguration[] {

    const sections: SectionDetailDataConfiguration[] = [];

    if (value.confezioni.length) {

      value
        .confezioni
        .forEach(confezioneSpedizione => {

          const currentSection: SectionDetailDataConfiguration = SectionDetailDataConfiguration
            .create(`${confezioneSpedizione.quantita} x ${confezioneSpedizione.confezione?.nome}`)
            .enableEndSectionDivider(true);

          const contenutiData = this.getContenutiData(confezioneSpedizione.contenuti);

          currentSection
            .dataList
            .push(...contenutiData);

          sections
            .push(currentSection);

        });

    }

    if ((value?.pallets ?? []).length) {

      value
        ?.pallets
        ?.forEach(pallet => {

          if (!pallet.giacenzaPallet) return;

          const currentSection: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaPalletSectionsFromGiacenzaPalletModel(pallet.giacenzaPallet, pallet.quantita);

          sections
            .push(...currentSection);

        });

    }

    if (value.scatole.length) {

      value
        .scatole
        .forEach(scatola => {

          if (!scatola.prodotto) return;

          const currentSection: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaScatolaSectionsFromGiacenzaVinoModel({ vino: scatola.prodotto as GenericReadModel } as any, scatola.quantita);

          sections
            .push(...currentSection);

        });

    }

    return sections;

  }

  private getContenutiData(contenuti: Array<ContenutoConfezioneSpedizione> | null = []) {

    const contenutiData: DetailDataConfiguration[] = [];
    const quantity = this.translate.instant("CT_GENERAL.quantity");

    contenuti
      ?.forEach(contenuto => {

        const detailData = DetailDataConfiguration
          .create()
          .setCTCardConfigurationFromDetailDataModel(
            contenuto.prodotto?.descrizione ?? "",
            `${quantity} ${contenuto.quantitaProdotto}`)
          .setFxFlex(null)
          .setFxLayout(null);

        contenutiData
          .push(detailData);

      });

    return contenutiData;

  }

  async getShipmentModelDetail(shipmentId: string | null): Promise<ModelDetailConfiguration | null> {

    const model = await this.getShipmentData(shipmentId);

    if (!model) return null;

    const modelDetail: ModelDetailConfiguration = ModelDetailConfiguration
      .create()
      .setTitle("CT_GENERAL.ritiro_title");

    //#region set destinatario section

    const dettaglioDestinatarioSection = this.getDestinatarioSpedizioneModelDetailSection(model);

    //#endregion set destinatario section

    //#region set indirizzo section

    const dettaglioIndirizzoSection = this.getIndirizzoSpedizioneModelDetailSection(model);

    //#endregion set indirizzo section

    //#region set colli spedizione section

    const colliSpedizioneSection: SectionDetailDataConfiguration[] = this.getColliSpedizioneModelDetailSections(model);

    //#endregion set colli spedizione section

    return modelDetail
      .addSection(dettaglioDestinatarioSection)
      .addSection(dettaglioIndirizzoSection)
      .addSections(colliSpedizioneSection);

  }

  showDetail(shipmentId: string) {

    this.getShipmentModelDetail(shipmentId)
      .then(result => {

        if (!result) return;

        this.detailDataService.open(result);

      });

  }

}
