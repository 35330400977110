import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CourierRateTableComponent } from "./courier-rate-table.component";
import { CourierRateTableService } from "./courier-rate-table.service";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import { MatListModule } from "@angular/material/list";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { MatProgressBarModule } from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    CourierRateTableComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    TranslateModule,
    MatListModule,
    CtButtonModule,
    MatProgressBarModule
  ],
  providers: [
    CourierRateTableService
  ],
  exports: [CourierRateTableComponent]
})
export class CourierRateTableModule {
}
