<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    {{ 'CT_RIEPILOGO' | translate }}
    <button mat-icon-button color="primary" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div class="summary-container">
    <div class="summary-item" *ngFor="let item of summaryItems">
      <p class="summary-title">{{ item.title }}</p>
      <p class="summary-value">{{ item.value }}</p>
    </div>
  </div>
  
  <div *ngIf="selectedRate?.tariffa >= 0" class="rate-summary">
    <p class="m-t-20 m-b-0">
      <strong [innerHTML]='"CT_SHIPMENT_DEPOSITS.selectedFee" | translate'></strong>
    </p>
    {{ 'CT_SHIPMENT_DEPOSITS.courierName' | translate }} {{ selectedRate?.nomeCorriere }} -
    {{ selectedRate?.giorniConsegna }} {{ 'CT_SHIPMENT_DEPOSITS.shipmentDeliveryData' | translate }}
    <strong>{{ selectedRate?.tariffa ?? 0 | number:'1.2' }} {{ selectedRate?.valutaTariffa }}</strong>
  </div>
  
  <button
    *ngIf="selectedRate?.tariffa !== null && selectedRate?.tariffa >= 0"
    mat-button
    type="button"
    class="general-button"
    (click)="submit()"
  >
    {{ 'CT_SHIPMENT_DEPOSITS_Conferma' | translate }}
  </button>