import { Component, Inject, ViewChild, Output, EventEmitter, ChangeDetectorRef, Input } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { RitiroDeposito } from "../../core/classes/deposito/ritiro-deposito";
import { BaseRequestClass } from "../../core/classes/apollo/apollo.base.request";
import { DepositiControllerRequest, ShipmentControllerRequest } from "../../core/controllers/controllers";
import { BaseController } from "../../core/controllers/base.controller";
import { Rate } from "../../core/interfaces/rate";
import { MatSelectionList } from "@angular/material/list";
import { CtButtonConfiguration } from "@ctsolution/ct-framework";
import { SpedizioneModel } from "../../core/classes/spedizione/spedizione.model";

@Component({
  selector: "app-courier-rate-table",
  templateUrl: "./courier-rate-table.component.html"
})
export class CourierRateTableComponent {

  @ViewChild("options") options: MatSelectionList | null = null;

  rates: Rate[] = [];
  loading: boolean = false;

  @Output() selectedRate = new EventEmitter<Rate>();
  @Input() isConfirm: boolean = false;
  

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setClass("general-button save")
    .setAction(() => this.execute());

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: RitiroDeposito | SpedizioneModel,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatLegacyDialogRef<any>,
    private baseController: BaseController) {
  }

   ngOnInit() {

    if(this.isConfirm == true){
      this.close();
      return;
    }
    
     let request: any;

     if (this.data instanceof RitiroDeposito) {

       request = DepositiControllerRequest().setAction("tariffe-ritiro");

       this.submitButton
         .setLabel("CTPAGE.CTFORM.save-deposit")

     } else if (this.data instanceof SpedizioneModel) {

       request = ShipmentControllerRequest().setAction("tariffe");

       this.submitButton
         .setLabel("CTPAGE.CTFORM.save-shipment")

     } else {

       this.close();
       return;

     }

     this.calculateRates(request)
       .then();

  }

  close() {
//Chiude la modale senza restituire valore
    this.dialogRef
      .close();

  }

  execute() {
    if (this.options?.selectedOptions.hasValue() == false) {
    return; 
  }

  const selectedRate = this.options?.selectedOptions.selected[0].value;

  this.dialogRef.close(selectedRate);
  }

  async calculateRates(request: BaseRequestClass) {

    this.loading = true;

    (<any>this.data)["hideSpinner"] = true;

    const parameter: BaseRequestClass = request.setDataSource(this.data);

    parameter
      .bypassIdSetting = true;

     const caller = await this.baseController.executeDepositorEntityRequest<any>(parameter);

    if (!caller) return;

     caller
       .subscribe({
         next: (response: { rates: Rate[] }) => {

           this.rates = response.rates;
           this.loading = false;

         },
         error: err => this.loading = false
       });

  }

}
