<div class="deposit-create-step4-container">

  <form [formGroup]="form">

    <app-deposit-collection-packaging
      #depositCollectionPackagingComponent></app-deposit-collection-packaging>

    <app-deposit-collection-form
      #depositCollectionForm
      [configuration]="depositCollectionFormComponentConfiguration" [selectedRate]="selectedRate"></app-deposit-collection-form>

  </form>

  <hr>

  <div class="buttons-stepper-step4">

    <button class="general-button" mat-button matStepperPrevious>{{ 'CT_GENERAL.BACK' | translate }}</button>

    <div class = "buttons-step4-tarifs">
      <button
        *ngIf="selectedRate?.tariffa !== null && selectedRate?.tariffa >= 0"
        mat-button
        type="button"
        class="general-button"
        (click)="confirmDeposit.emit(null)"
        >{{ "Conferma deposito" }}
      </button> 
  
      <button
        mat-button
        type="button"
        class="general-button"
        (click)="onSubmit.emit(null)">{{ closingButtonLabel | translate }}
      </button>
    </div>
    
  </div>
    
  

  

</div>
