import { MediaMatcher } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { Direction } from "@angular/cdk/bidi";
import { KeycloakService } from "keycloak-angular";
import { LocalStorageService } from "src/app/core/lib/local-storage.service";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: []
})
export class FullComponent implements OnDestroy {

  mobileQuery: MediaQueryList;

  dir: Direction = "ltr";
  dark = false;
  minisidebar = false;
  boxed = false;

  sidebarOpened = false;
  status = false;

  public selectedLanguage: any = {
    language: "Italian",
      code: "it",
      type: "IT",
      icon: "it"
  };

  public languages: any[] = [
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us"
    },
    {
      language: "French",
      code: "fr",
      type: "FR",
      icon: "fr"
    },
    {
      language: "Italian",
      code: "it",
      type: "IT",
      icon: "it"
    }
  ];

  // tslint:disable-next-line - Disables all
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    private translate: TranslateService,
    private keycloakService: KeycloakService,
    private localStorageService: LocalStorageService
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 1100px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.dark = false;

  }

  ngOnInit(): void {
    
    
    let code = this.localStorageService.getLanguage();
    if(code){

      this.selectedLanguage = this.languages.find(lang => lang.code === code);

    }

    
  }

  clickEvent(): void {

    this.status = !this.status;

  }

  darkClick() {

    const body = document.getElementsByTagName("body")[0];
    body.classList.toggle("dark");

  }

  changeLanguage(lang: any): void {

    this.translate.setDefaultLang(lang.code);
    this.selectedLanguage = lang;
    this.localStorageService.setLanguage(lang.code);
    const url = this.router.url;
  
    this.translate.use(lang.code).subscribe(() => {
      this.translate.reloadLang(lang.code).subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.router.navigateByUrl(url);
        });
      });
    });
 
  }

  logout() {

    this.keycloakService
      .logout();

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
