<div class="shipment-create-cntr">

  <div fxLayout="row wrap">

    <div fxFlex="100" fxFlex.gt-md="70">

      <app-shipment-create-step1 #step1></app-shipment-create-step1>

    </div>

    <div fxFlex="100" fxFlex.gt-md="30">

      <app-shipment-create-step2 #step2 (onSubmit)="submit()"></app-shipment-create-step2>

      <div align="end" class="p-10">

      <div class = "buttons-wizard-tarifs">
        
        <button
          *ngIf="selectedRate?.tariffa !== null && selectedRate?.tariffa >= 0 && isFormValid"
          mat-button
          type="button"
          class="general-button"
          (click)="submit(true)"
          >{{ 'CT_SHIPMENT_DEPOSITS.Vai al riepilogo' | translate}}
        </button>

        

        <button
          mat-button
          type="button"
          class="general-button"
          (click)="submit()">{{ 'CT_SHIPMENT_DEPOSITS.calculateFees' | translate }}
        </button>
      </div>

      </div>

    </div>

  </div>

</div>
