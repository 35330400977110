import { Injectable, Input } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { Rate } from "../../../../core/interfaces/rate";
import { SpedizioneModel } from "../../../../core/classes/spedizione/spedizione.model";
import { ShipmentSummaryComponent } from "./shipment-summary.component";

@Injectable({
  providedIn: "root"
})
export class ShipmentSummaryService {
@Input() confirmShipment: boolean = false;

  constructor(private dialog: MatLegacyDialog) {
  }

  open(data: SpedizioneModel,selectedRate: Rate): Promise<boolean> | false {

      return new Promise((resolve) => {

        this.dialog.open(ShipmentSummaryComponent, { data:{data,selectedRate} })
          .afterClosed()
          .subscribe((response?: true | false) => resolve(response ?? false));
      });
    
  }
}
