import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositCollectionPackagingComponent } from "./deposit-collection-packaging.component";
import { CtFormModule } from "@ctsolution/ct-framework";
import { TranslateModule } from "@ngx-translate/core";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { QtaControlModule } from "../../../../../components/qta-control/qta-control.module";
import {
    SectionDetailDataModule
} from "../../../../../components/model-detail/section-detail-data/section-detail-data.module";
import { CollectionPalletPackagingComponent } from './collection-pallet-packaging/collection-pallet-packaging.component';
import { CollectionFormComponent } from './collection-form/collection-form.component';
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TipologiaImballoSelectComponent } from './tipologia-imballo-select/tipologia-imballo-select.component';
import { MwLookupControlModule } from "../../../../../components/mw-lookup-control/mw-lookup-control.module";
import { CollectionDefaultPackagingComponent } from "./collection-default-packaging/collection-default-packaging.component";

@NgModule({
  declarations: [
    DepositCollectionPackagingComponent,
    CollectionPalletPackagingComponent,
    CollectionFormComponent,
    TipologiaImballoSelectComponent,
    CollectionDefaultPackagingComponent
  ],
  imports: [
    CommonModule,
    CtFormModule,
    TranslateModule,
    MatIconModule,
    FlexModule,
    ReactiveFormsModule,
    QtaControlModule,
    SectionDetailDataModule,
    MatLegacyButtonModule,
    MwLookupControlModule
  ],
  exports: [DepositCollectionPackagingComponent]
})
export class DepositCollectionPackagingModule {
}
