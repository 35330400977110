import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DepositType } from "../../../../core/enum/deposit-type";
import {
  DepositCollectionPackagingComponent
} from "./deposit-collection-packaging/deposit-collection-packaging.component";
import { DepositFormDTO } from "../deposit-create-wizard.interfaces";
import { DepositCollectionFormComponent } from "./deposit-collection-form/deposit-collection-form.component";
import {
  DepositCollectionFormComponentConfiguration
} from "./deposit-collection-form/deposit-collection-form-component.configuration";
import { Rate } from "src/app/core/interfaces/rate";

@Component({
  selector: "app-deposit-create-step4",
  templateUrl: "./deposit-create-step4.component.html",
  styleUrls: ["./deposit-create-step4.component.scss"]
})
export class DepositCreateStep4Component {

  @ViewChild("depositCollectionForm") depositCollectionForm: DepositCollectionFormComponent | null = null;
  @ViewChild("depositCollectionPackagingComponent") depositCollectionPackagingComponent: DepositCollectionPackagingComponent | null = null;
  @Output() onSubmit: EventEmitter<null> = new EventEmitter<null>();
  @Output() confirmDeposit: EventEmitter<null> = new EventEmitter<null>();
  @Input() selectedRate: Rate | null = null;
  @Input() isConfirmDeposit: boolean = false;

  form: FormGroup;
  depositCollectionFormComponentConfiguration: DepositCollectionFormComponentConfiguration = DepositCollectionFormComponentConfiguration.create();

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

  }

  get closingButtonLabel() {

    return this.depositCollectionForm?.isRitiro ? "CT_SHIPMENT_DEPOSITS.calculateFees" : "CTPAGE.CTFORM.save-deposit";

  }

  ngAfterViewInit() {

    this.form
      .addControl("datiAggiuntivi", this.depositCollectionForm?.form);

    this.form
      .addControl("colliRitiro", this.depositCollectionPackagingComponent?.form);

  }

  setup(value: DepositFormDTO) {

    const depositType: DepositType | null = value?.step3?.depositType;

    this.depositCollectionFormComponentConfiguration.setDepositType(depositType);

    setTimeout(() => this.depositCollectionForm?.setup(), 100);

    this.depositCollectionPackagingComponent?.setup(value);

  }

}
