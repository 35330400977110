import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MwCardRadioGroupConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-group.configuration";
import { TipoSpedizioneEnum } from "../../../../../core/enum/tipo-spedizione.enum";
import {
  MwCardRadioOptionConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-option/mw-card-radio-option.configuration";
import { iconsBasePath } from "../../../../../core/constants";

const WineStockShipmentTypeCardRadioOptionConfigurations: MwCardRadioOptionConfiguration<TipoSpedizioneEnum>[] = [
  MwCardRadioOptionConfiguration
    .create<TipoSpedizioneEnum>()
    .setImage(`${iconsBasePath}box.svg`)
    .setValue(TipoSpedizioneEnum.GiacenzaScatola)
    .setLabel(`Spedisci scatola`),
  MwCardRadioOptionConfiguration
    .create<TipoSpedizioneEnum>()
    .setValue(TipoSpedizioneEnum.ComposizioneBox)
    .setImage(`${iconsBasePath}bottles.svg`)
    .setLabel('CT_DATATABLE.SHIPMENT.CreateCustomBox')
];


@Component({
  selector: "app-wine-stock-shipment-type",
  template: `
    <div class="wine-stock-shipment-type-selector">
      <mw-card-radio-group [configuration]="mwCardRadioGroupConfiguration"></mw-card-radio-group>
    </div>
  `,
  styles: [`
    ::ng-deep .wine-stock-shipment-type-selector {
      .mw-card-radio-group .card-container {
        padding: 10px 15px !important;
      }

      mat-card {
        height: 100%;
      }
    }
  `]
})
export class WineStockShipmentTypeComponent {

  control: FormControl = new FormControl(null, Validators.required);

  mwCardRadioGroupConfiguration: MwCardRadioGroupConfiguration<TipoSpedizioneEnum> = MwCardRadioGroupConfiguration
    .create<TipoSpedizioneEnum>()
    .setControl(this.control)
    .setOptions(WineStockShipmentTypeCardRadioOptionConfigurations)
    .enableHorizontalView(true);

}
