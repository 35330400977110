import { NgModule } from "@angular/core";
import { ShipmentSummaryComponent } from "./shipment-summary.component";
import { ShipmentSummaryService } from "./shipment.summary.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { DepositCollectionFormModule } from "../../../deposit/deposit-create-wizard/deposit-create-step4/deposit-collection-form/deposit-collection-form.module";
import { DepositCollectionPackagingModule } from "src/app/pages/deposit/deposit-create-wizard/deposit-create-step4/deposit-collection-packaging/deposit-collection-packaging.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [
        ShipmentSummaryComponent
    ],
    imports: [
        TranslateModule,
        MatIconModule,
        CommonModule,
        DepositCollectionFormModule
    ],
    exports:[
        ShipmentSummaryComponent
    ]
})
export class ShipmentSummaryModule{}