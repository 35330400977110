import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { CTGeneralService } from "@ctsolution/ct-framework";
import { BaseRequestClass } from "src/app/core/classes/apollo/apollo.base.request";
import { SpedizioneModel } from "src/app/core/classes/spedizione/spedizione.model";
import { BaseController } from "src/app/core/controllers/base.controller";
import { ShipmentControllerRequest } from "src/app/core/controllers/controllers";
import { Rate } from "src/app/core/interfaces/rate";
import { SnackBarService } from "src/app/core/lib/snakbar.service";

@Component({
    selector: "app-shipment-summary",
    templateUrl: "./shipment-summary.component.html"
  })
  export class ShipmentSummaryComponent {
    @ViewChild("options") options: any | null = null;
  
    selectedRate: Rate | null = null;
    spedizioneModel: SpedizioneModel | null = null;
    summaryItems: { title: string; value: string }[] = [];


    constructor(
      @Inject(MAT_LEGACY_DIALOG_DATA) public data: { data: SpedizioneModel; selectedRate: Rate }, // Ricevi i dati
      private dialogRef: MatLegacyDialogRef<any>,
      private baseController: BaseController,
      private snackBarService: SnackBarService,
      private generalService: CTGeneralService
    ) {

      this.spedizioneModel = data.data;
      this.selectedRate = data.selectedRate;
      this.populateSummaryItems();
    }
  
    populateSummaryItems() {
      if (this.spedizioneModel) {
        this.summaryItems = [
          { title: 'Codice', value: this.spedizioneModel.tariffaId || '' },
          { title: 'Corriere', value: this.spedizioneModel.corriereId || '' },
          { title: 'Destinatario', value: this.spedizioneModel.destinatario?.nome || '' },
          { title: 'Indirizzo', value: `${this.spedizioneModel.destinatario?.indirizzo?.via || ''}, ${this.spedizioneModel.destinatario?.indirizzo?.comune || ''}` },

        ];
      }
    }

    
    async submit(){

      const parameter: BaseRequestClass = ShipmentControllerRequest()
      .setDataSource(this.spedizioneModel);

      const caller = await this.baseController.executeDepositorEntityRequest<SpedizioneModel>(parameter);

      if (!caller){
        this.dialogRef.close();
        return;
      }
      caller
       .subscribe(() => {

        this.dialogRef.close();
        this.snackBarService
          .generalMessage("CT_GENERAL.added_shipment_success");

        this.generalService.navigateTo(["spedizioni"]);

       });

    }

    async close(){
      this.dialogRef.close();
    }

  }