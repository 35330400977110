import { Injectable, Input } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CourierRateTableComponent } from "./courier-rate-table.component";
import { RitiroDeposito } from "../../core/classes/deposito/ritiro-deposito";
import { Rate } from "../../core/interfaces/rate";
import { SpedizioneModel } from "../../core/classes/spedizione/spedizione.model";

@Injectable({
  providedIn: "root"
})
export class CourierRateTableService {
@Input() confirmDeposit: boolean = false;
  constructor(private dialog: MatLegacyDialog) {
  }

  open(data: RitiroDeposito | SpedizioneModel, isConfirm = false): Promise<Rate | null> | null {

    if(isConfirm == false){
      return new Promise((resolve) => {

        this.dialog.open(CourierRateTableComponent, { data })
          .afterClosed()
          .subscribe((response?: Rate | null) => resolve(response ?? null));
  
      });
    }
    return null;
  }

}
