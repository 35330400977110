import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ShipmentCreateStep1Component } from "./shipment-create-step1/shipment-create-step1.component";
import { ShipmentCreateStep2Component } from "./shipment-create-step2/shipment-create-step2.component";
import { CustomerControllerRequest, ShipmentControllerRequest } from "../../../core/controllers/controllers";
import { BaseController } from "../../../core/controllers/base.controller";
import { ActivatedRoute } from "@angular/router";
import { QueriesService } from "../../../core/lib/queries.service";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import {
  SectionDetailDataConfiguration
} from "../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../../components/model-detail/model-detail.service";
import { TipoSpedizioneEnum } from "../../../core/enum/tipo-spedizione.enum";
import {
  ShipmentCollectionConfiguration
} from "./shipment-create-step1/shipment-collection/shipment-collection.configuration";
import { SpedizioneModel } from "../../../core/classes/spedizione/spedizione.model";
import { ShipmentCreateWizardValue } from "./shipment-create-wizard.interfaces";
import { DestinatarioSpedizione } from "../../../core/classes/spedizione/destinatario-spedizione";
import { GiacenzaViniApolloResult } from "../../../core/interfaces/apollo/giacenza-vini.apollo-result";
import { GiacenzaPalletApolloResult } from "../../../core/interfaces/apollo/giacenza-pallet.apollo.result";
import { CourierRateTableService } from "../../../components/courier-rate-table/courier-rate-table.service";
import { DestinazioneModel } from "src/app/core/classes/destinazione";
import { IndirizzoModel } from "src/app/core/classes/indirizzo";
import { ChangeDetectorRef} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { Rate } from "src/app/core/interfaces/rate";
import { ShipmentSummaryService } from "./shipment-summary/shipment.summary.service";
import { ShipmentSummaryComponent } from "./shipment-summary/shipment-summary.component";
import { distinctUntilChanged } from "rxjs";

@Component({
  selector: "app-shipment-create-wizard",
  templateUrl: "./shipment-create-wizard.component.html",
  styleUrls: ["./shipment-create-wizard.component.scss"]
})
export class ShipmentCreateWizardComponent {

  @ViewChild("step1") shipmentCreateStep1: ShipmentCreateStep1Component | null = null;
  @ViewChild("step2") shipmentCreateStep2: ShipmentCreateStep2Component | null = null;
  @ViewChild("summary") shipmentSummary: ShipmentSummaryComponent | null = null;
  
  @Output() confirmShipment: EventEmitter<null> = new EventEmitter<null>();

  form: FormGroup;
  selectedRate: Rate = {
      tariffa: -1,
      giorniConsegna: "",
      id: "",
      idCorriere: "",
      nomeCorriere:"",
      servizio: "",
      valutaTariffa: "EUR"
    };
  isConfirmShipment = false;
  isFormValid = false;

  constructor(
    private formBuilder: FormBuilder,
    private baseController: BaseController,
    private _queries: QueriesService,
    private courierRateTableService: CourierRateTableService,
    private modelDetailService: ModelDetailService,
    private shipmentSummaryService: ShipmentSummaryService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private title: Title) {

    this.form = this.formBuilder.group({});

    this.route
      .queryParams
      .subscribe(
        qp => {

          if (qp["giacenzaPalletId"]) {

            this.setupGiacenzaPallet(qp["giacenzaPalletId"]);

          }

          if (qp["giacenzaProdottoId"]) {

            this.setupGiacenzaProdotto(qp["giacenzaProdottoId"], qp["tipoSpedizione"] as TipoSpedizioneEnum);

          }

        }
      );

  }

  ngAfterViewInit() {

    this.form
      .addControl("step1", this.shipmentCreateStep1?.form);

    this.form
      .addControl("step2", this.shipmentCreateStep2?.form);

    this.form.valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(ShipmentCreateWizardValue => {
      this.isFormValid = false;
    })

    this.cdr.detectChanges();

    this.translate.get('CT_SHIPMENT_DEPOSITS.yourShipments').subscribe((translation: string) => {
      this.title.setTitle(translation);
    });

  }

  async setupGiacenzaPallet(id: string | null) {

    let query = await this._queries.entities.giacenzaPallet();

    if (!query) return null;

    const variables = ApolloVariableClass
      .create()
      .setId(id);

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    const observable = await this.baseController
      .list<GiacenzaPalletApolloResult>(parameter);

    observable
      ?.subscribe(result => {

        const giacenza = result.giacenzaPallet;

        if (giacenza) {

          const sections: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaPalletSectionsFromGiacenzaPalletModel(giacenza);

          const collectionConfiguration = ShipmentCollectionConfiguration
            .create()
            .setSections(sections)
            .setTipoSpedizione(TipoSpedizioneEnum.GiacenzaPallet);

          this.shipmentCreateStep1
            ?.updateCollection(collectionConfiguration);

        }

      });

  }

  async setupGiacenzaProdotto(id: string, tipoSpedizione: TipoSpedizioneEnum = TipoSpedizioneEnum.ComposizioneBox) {
    // TODO: modificare con la query giacenzaVino() che WineSpaces ci deve fornire, questa ci darà il risultato diretto senza richiedere la list
    let query = await this._queries.entities.giacenzaVini();

    if (!query) return null;

    const variables = ApolloVariableClass
      .create()
      .setWhereAndFilters([
        { vino: { id: { eq: id } } }
      ]);

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    const observable = await this.baseController
      .list<GiacenzaViniApolloResult>(parameter);

    observable
      ?.subscribe(result => {

        const giacenza = (result
          .giacenzaVini
          .nodes ?? [])[0];

        if (giacenza) {

          const collectionConfiguration = ShipmentCollectionConfiguration
            .create();

          if (tipoSpedizione === TipoSpedizioneEnum.GiacenzaScatola) {

            const sections: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaScatolaSectionsFromGiacenzaVinoModel(giacenza);

            collectionConfiguration
              .setSections(sections)
              .setTipoSpedizione(TipoSpedizioneEnum.GiacenzaScatola);

            setTimeout(() => {

              this.shipmentCreateStep1
                ?.updateCollection(collectionConfiguration);

            });

            return;

          }

          collectionConfiguration
            .setTipoSpedizione(TipoSpedizioneEnum.ComposizioneBox)
            .addAttribute("giacenzaVino", giacenza);

          setTimeout(() => {

            this.shipmentCreateStep1
              ?.openPackageComposerDialog(collectionConfiguration);

          });

        }

      });

  }


  async submit(openSummary = false) {
    debugger;
    this.form.markAllAsTouched();
    if (this.form.valid) {

      let newDestinazione : DestinazioneModel | null = null;

      const value: ShipmentCreateWizardValue = this.form.value;

      if(!value.step2.shipmentAddressData.customer)
      {

        const destinazioneModel : DestinazioneModel = DestinazioneModel.create()
        .setIndirizzo(value.step2.shipmentAddressData.indirizzo)
        .setContact(value.step2.shipmentAddressData.contatto) 
        .setIsBusiness(false);           

        const parameter = CustomerControllerRequest();

        destinazioneModel.setId(parameter.id);
        newDestinazione = destinazioneModel;

      }

       else
       {
        newDestinazione = DestinazioneModel.create()
        newDestinazione = (<any>value.step2.shipmentAddressData.customer).value
       }


       if(!newDestinazione) return;

       const indirizzo : IndirizzoModel =
       {

        via: newDestinazione.indirizzo?.via ?? "",
        comune: newDestinazione.indirizzo?.comune ?? "",
        cap: newDestinazione.indirizzo?.cap ?? "",
        stato: newDestinazione.indirizzo?.stato ?? null,
        StatoId: newDestinazione.indirizzo?.stato?.id ?? "",
        provincia: newDestinazione.indirizzo?.provincia ?? "",

       }

      const destinatarioSpedizione: DestinatarioSpedizione = DestinatarioSpedizione
        .create(newDestinazione.nome ?? "", newDestinazione.telefono ?? "", indirizzo)
        .setEmail(newDestinazione.email ?? "");



      const spedizioneModel: SpedizioneModel = SpedizioneModel
        .create(newDestinazione.id ?? "", destinatarioSpedizione)
        .setupCollection(value.step1.shipmentCollectionData.collection, this.modelDetailService);

        var rate : Rate | null = null;
        
        if(this.selectedRate != null && this.selectedRate.tariffa >= 0 && openSummary == true)
          rate = this.selectedRate;
        else
          rate = await this.courierRateTableService.open(spedizioneModel);
        
        if(openSummary == false)
        {
          if (!rate) return;
          this.selectedRate = rate;
          this.isFormValid = true;
          return;
        }

      spedizioneModel
        ?.setCorriereId(this.selectedRate.idCorriere)
        ?.setTariffaId(this.selectedRate.id);

        var result = await this.shipmentSummaryService.open(spedizioneModel,this.selectedRate);
        debugger;
    }

  }

  


}
