import { Component } from "@angular/core";
import {
  CtButtonConfiguration,
  CtCardConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition,
  CtCardTemplate
} from "@ctsolution/ct-framework";
import { GeneralService } from "../../core/lib/general.service";
import { BaseController } from "../../core/controllers/base.controller";
import { DepositorService } from "../../core/lib/depositor.service";
import { QueriesService } from "../../core/lib/queries.service";
import { ActivatedRoute } from "@angular/router";
import { JwtService } from "../../core/lib/jwt.service";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { SpedizioniApolloResult } from "../../core/interfaces/apollo/spedizioni.apollo-result";
import { ViniApolloResult } from "../../core/interfaces/apollo/vini.apollo-result";
import { DepositiApolloResult } from "../../core/interfaces/apollo/depositi.apollo-result";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent {

  viewModel = {

    username: "User"

  };

  DEPOSIT_CREATE: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_ACTIONS.add-deposit")
    .setDescription("DASHBOARD.DEPOSIT_WIDGET.description")
    .setIconSetting(
      CtCardIconConfiguration
        .create()
        .setIcon(
          "warehouse")
        .setClass("bg-dashboard-fourth text-white")
        .setPosition(CtCardIconPosition.SUFFIX))
    .setClass("bg-dashboard-fourth text-white")
    .setAction(() => this.general.navigateTo("/depositi/nuovo-deposito"))
    .setButton(
      CtButtonConfiguration.create()
        .setIcon("arrow_forward"));

  SHIPMENT_CREATE: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_ACTIONS.add-shipment")
    .setDescription("DASHBOARD.SHIPMENT_WIDGET.description")
    .setIconSetting(
      CtCardIconConfiguration
        .create()
        .setIcon(
          "local_shipping")
        .setClass("bg-dashboard-secondary text-white")
        .setPosition(CtCardIconPosition.SUFFIX))
    .setClass("bg-dashboard-secondary text-white")
    .setAction(() => this.general.navigateTo("/spedizioni/creazione"))
    .setButton(
      CtButtonConfiguration.create()
        .setIcon("arrow_forward"));

  SHIPMENT_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_MENU.shipments")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-dashboard-secondary text-white")
    .setAction(() => this.general.navigateTo("/spedizioni"));

  DEPOSIT_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_MENU.deposits")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-dashboard-fourth text-white")
    .setAction(() => this.general.navigateTo("/depositi"));

  PRODUCT_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_PAGES.WINES")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-dashboard-tertiary text-white")
    .setAction(() => this.general.navigateTo("/vini"));

  MOVEMENTS_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("CT_PAGES.MOVEMENTS")
  .setTemplate(CtCardTemplate.COUNTER)
  .setClass("bg-dashboard-tertiary text-white")
  .setAction(() => this.general.navigateTo("/movimentazioni"));

  constructor(
    private general: GeneralService,
    private depositorService: DepositorService,
    private controller: BaseController,
    private _queries: QueriesService,
    private route: ActivatedRoute,
    private jwt: JwtService,
    private translate: TranslateService,
    private title: Title
  ) {
  }

  ngOnInit() {

    this.setupCounters();
    
    this.route
      .data
      .subscribe(async data => {

        // TODO: mettere a posto il welcome greeting con il nome dell'utente (dopo aggiornamento Luca non arriva più 'given_name' nel jwt)
        // const name = await this.jwt.getGivenName();
        // data.title = `${this.translate.instant('Welcome_Greeting')}`; 
        // data.name = `${name}`;
        this.translate.get('CT_MENU.your_dashboard').subscribe((translation: string) => { // Traduce nell'init la tab del browser
          this.title.setTitle(translation);
        });
  
      });


  }

  async setupCounters() {

    const depositanteId = await this.depositorService.getDepositor();

    const variables =
      ApolloVariableClass
        .create()
        .setDepositanteId(depositanteId);

    const spedizioni_query = await this._queries.entities.spedizioni();

    if (spedizioni_query) {

      const shipmentParameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(spedizioni_query);

      const shipmentCaller = await this.controller.list<SpedizioniApolloResult>(shipmentParameter);

      if (shipmentCaller) {

        shipmentCaller
          .subscribe(response => {
            
            this.SHIPMENT_COUNTER
              .setDescription((response.spedizioni?.totalCount ?? 0) > 0 ? response.spedizioni.totalCount : "0");

          });

      }

    }

    const prodotti_query = await this._queries.entities.vini();

    if (prodotti_query) {

      const productParameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(prodotti_query);

      const productCaller = await this.controller.list<ViniApolloResult>(productParameter);

      if (productCaller) {

        productCaller
          .subscribe(response => {

            this.PRODUCT_COUNTER
              .setDescription((response.vini?.totalCount ?? 0) > 0 ? response.vini.totalCount : "0");

          });

      }

    }

    const depositi_query = await this._queries.entities.depositi();

    if (depositi_query) {

      const depositParameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(depositi_query);

      const depositCaller = await this.controller.list<DepositiApolloResult>(depositParameter);

      if (depositCaller) {

        depositCaller
          .subscribe(response => {

            this.DEPOSIT_COUNTER
              .setDescription((response.depositi?.totalCount ?? 0) > 0 ? response.depositi.totalCount : "0");

          });

      }

    }

  }

}
