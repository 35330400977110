<form [formGroup]="form">

  <div class="w-100 m-t-5">

    <ct-control *ngIf="dataRitiroControl" [configuration]="dataRitiroControl"></ct-control>

  </div>

  <div
    [hidden]="!form.get('shipmentAddressData')"
    class="m-t-5">

    <app-shipment-address-form
      #shipmentAddressFormComponent
      [configuration]="shipmentAddressFormConfiguration"></app-shipment-address-form>

  </div>

  <div *ngIf="selectedRate?.tariffa >= 0">
    <p class="m-t-20 m-b-0"><strong [innerHTML]='"CT_SHIPMENT_DEPOSITS.selectedFee" | translate'></strong></p>
    {{ 'CT_SHIPMENT_DEPOSITS.courierName' | translate }} {{selectedRate?.nomeCorriere}} - 
    {{ selectedRate?.giorniConsegna }} {{ 'CT_SHIPMENT_DEPOSITS.shipmentDeliveryData' | translate}}
    <strong>{{ selectedRate?.tariffa ?? 0 | number:'1.2'}} {{ selectedRate?.valutaTariffa }}</strong>
  </div>

  <p class="m-t-20 m-b-0"><strong [innerHTML]='"CT_SHIPMENT_DEPOSITS.note_title" | translate'></strong></p>
  <p class="m-t-0 m-b-20" [innerHTML]='"CT_SHIPMENT_DEPOSITS.note_subtitle" | translate'></p>
  <app-note-control #depositNoteComponent></app-note-control>

</form>
